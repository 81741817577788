
<template>
  <section>

  <!-- OneTrust Privacy Notice start -->
  <!-- Language Drop-down elements that will control in which language notice is displayed -->
      <div class="otnotice-language-dropdown-container">
        <select id="otnotice-language-dropdown" aria-label="language selector"></select>
      </div>



    <!-- Container in which the privacy notice will be rendered -->
    <div id="otnotice-a4f0238f-9e60-4051-8b48-26a686c640de" class="otnotice"></div>

    <!-- OneTrust Privacy Notice end -->
  </section>
</template>

<script>
export default {

  methods: {

    putPrivacyScriptInPlace(){

      console.log("----- privacy notice -----");
      console.log("Deploying privacy scripts");
      console.log("---------------------------");
      let privacyScript = document.createElement('script');
      privacyScript.setAttribute('src', 'https://privacyportalde-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js');
      privacyScript.setAttribute('type','text/javascript');
      privacyScript.setAttribute('charset','UTF-8');
      privacyScript.setAttribute('settings','eyJjYWxsYmFja1VybCI6Imh0dHBzOi8vcGVycmlnby1wcml2YWN5Lm15Lm9uZXRydXN0LmNvbS9yZXF1ZXN0L3YxL3ByaXZhY3lOb3RpY2VzL3N0YXRzL3ZpZXdzIn0=');
      privacyScript.setAttribute('id','otprivacy-notice-script');

      let privacyScript2 = document.createElement('script');
      privacyScript2.setAttribute('type','text/javascript');
      privacyScript2.setAttribute('charset','UTF-8');
      privacyScript2.textContent = 'OneTrust.NoticeApi.Initialized.then(function() {OneTrust.NoticeApi.LoadNotices(["https://privacyportalde-cdn.onetrust.com/5122cbe6-42bb-4462-a3b4-f863cf81253c/privacy-notices/a4f0238f-9e60-4051-8b48-26a686c640de.json"], false);});'

      document.getElementById("formPaper").appendChild(privacyScript);
      setTimeout(function(){document.getElementById("formPaper").appendChild(privacyScript2);},300);

      //document.body.prepend(privacyScript);
      //document.body.prepend(privacyScript2);

      console.log("function putPrivacyScriptInPlace")

    },
  },
  mounted() {

    this. putPrivacyScriptInPlace();

  }

}

</script>

<style scoped lang="scss">

</style>