<template>
	<div id="classicFooter">

		<ul>
			<li>
				<a :href="$t('footer.genterms')" @click.prevent="openPDFFile('conditions')" download tabindex="0">{{$t('footer.genterms')}}</a>
			</li>
      <li>
        <!-- Link must be specified in translations files to appears -->
        <router-link to="/privacy">{{$t('footer.privacy.label')}}</router-link>
      </li>
      <li>
        <!-- Link must be specified in translations files to appears -->
        <router-link to="/cookies">Cookie Statement</router-link>
      </li>
      <li>
        <!-- Link must be specified in translations files to appears -->
        <router-link to="/cookie-list">Cookie List</router-link>
      </li>
      <li>
        <button id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie Settings</button>
      </li>
		</ul>

		<ul style="margin-top:2em;">
			<!-- Link must be dynamic following the language -->
			<li>CONTACT :</li>
			<li>
				<a v-if="$i18n.locale === 'nl_BE' || $i18n.locale === 'nl_NL'" href="mailto:infolijn@promolife.be" tabindex="0">infolijn@promolife.be</a>
				<a v-if="$i18n.locale != 'nl_BE' && $i18n.locale != 'nl_NL'" href="mailto:infoligne@promolife.be" tabindex="0">infoligne@promolife.be</a>
			</li>
			<!--<li>
				<a v-if="$i18n.locale === 'nl_BE' || $i18n.locale === 'nl_NL'" href="tel:+3223520390">+32 2 352 03 90</a>
				<a v-if="$i18n.locale != 'nl_BE' && $i18n.locale != 'nl_NL'" href="tel:+3223520398">+32 2 352 03 98</a>			
			</li>-->
		</ul>

	</div>
</template>

<!-- ================================================================================== -->

<script>
	import openPDF from "../mixins/openpdf";

	export default {
		name:"ClassicFooter",
		mixins: [openPDF],
	}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>

</style>
