
<template>
  <!-- OneTrust Cookies List start -->
  <div id="ot-sdk-cookie-policy"></div>
  <!-- OneTrust Cookies List end -->



</template>

<script>

</script>

<style scoped lang="scss">

</style>