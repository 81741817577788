
<template>
  <section>
    <!-- OneTrust Privacy Notice start -->
    <!-- Language Drop-down elements that will control in which language notice is displayed -->
    <div class="otnotice-language-dropdown-container">
      <select id="otnotice-language-dropdown" aria-label="language selector"></select>
    </div>


    <!-- Container in which the privacy notice will be rendered -->
    <div id="otnotice-bff3553e-7384-4d67-b479-56bd3f78038f" class="otnotice"></div>

    <!-- OneTrust Privacy Notice end -->

  </section>

</template>

<script>
export default {

  methods: {
      putCookiesScriptInPlace(){
      console.log("----- cookies notice -----");
      console.log("Deploying cookies scripts");
      console.log("---------------------------");
      let cookiesScript = document.createElement('script');
      cookiesScript.setAttribute('src', 'https://privacyportalde-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js');
      cookiesScript.setAttribute('type','text/javascript');
      cookiesScript.setAttribute('charset','UTF-8');
      cookiesScript.setAttribute('id','otprivacy-notice-script');
      cookiesScript.setAttribute('settings','eyJjYWxsYmFja1VybCI6Imh0dHBzOi8vcGVycmlnby1wcml2YWN5Lm15Lm9uZXRydXN0LmNvbS9yZXF1ZXN0L3YxL3ByaXZhY3lOb3RpY2VzL3N0YXRzL3ZpZXdzIn0=');
      //document.head.appendChild(cookiesScript);
      document.getElementById("formPaper").appendChild(cookiesScript);

      let cookiesScript2 = document.createElement('script');
      cookiesScript2.textContent = 'OneTrust.NoticeApi.Initialized.then(function() {OneTrust.NoticeApi.LoadNotices(["https://privacyportalde-cdn.onetrust.com/5122cbe6-42bb-4462-a3b4-f863cf81253c/privacy-notices/bff3553e-7384-4d67-b479-56bd3f78038f.json"]);});'
      cookiesScript2.setAttribute('type','text/javascript');
      cookiesScript2.setAttribute('charset','UTF-8');
      //document.head.appendChild(cookiesScript2);
        setTimeout(function() {document.getElementById("formPaper").appendChild(cookiesScript2);},300);

      //document.body.prepend(cookiesScript);
      //document.body.prepend(cookiesScript2);
    },
  },
  mounted() {

    this.putCookiesScriptInPlace();

  }

}
</script>

<style scoped lang="scss">

</style>