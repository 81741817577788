/* Google API.js */
import {Loader} from '@googlemaps/js-api-loader';

export default {
	data() {
		return {

			
			baseGoogleMapsApiUrl: 'https://maps.googleapis.com/maps/api/place/autocomplete/json',
			apiKey: 'AIzaSyBlRYGUK4CyQGT_38p7ybcj6lvcuTl5A10',
			googleMaps: null,
			autocompleteService: null,
			placeService: null,
			
			placeId: null,
			foundPlaceData: null,
			predictions: [],
			searchTerm: '',
			selectList: [],
			selectedOption: null,
		}
	},

	methods: {

		findPlaceData() {
			// manque gestion des erreurs si pas de predictions
			try{
				return this.predictions?.find((prediction) => {
					return prediction.description.trim().toLowerCase() === this.selectedOption.trim().toLowerCase();
				});
			}catch(e){
				console.error(`Ho, no! An error occured :(   : ${e}`);
			}
		},

		findPlaceData2() {
			// manque gestion des erreurs si pas de predictions
			try{
				return this.predictions2.find((prediction) => {
					return prediction.description.trim().toLowerCase() === this.selectedOption2.trim().toLowerCase();
				});
			}catch(e){
				console.error(`ERREUR: ${e}`);
			}
		},

		populateDataFields() {
			this.fieldsValues.placeId = this.placeId;
			this.log(`J'ai placé ${this.fieldsValues.placeId} dans fieldsValues.placeID : `, 'low');
			this.log(this.foundPlaceData);

			this.foundPlaceData.forEach((addressData) => {
				if (addressData.types.includes('route')) {
					this.fieldsValues.street1 = addressData.long_name;
				}

				if (addressData.types.includes('locality')) {
					this.fieldsValues.city = addressData.long_name;
				}

				if (addressData.types.includes('postal_code')) {
					this.fieldsValues.postalcode = addressData.long_name;
				}

				if (addressData.types.includes('street_number')) {
					this.fieldsValues.housenumber = addressData.long_name;
				}	

				if (addressData.types.includes('country')) {
					/* Once for the data… */
					this.fieldsValues.idcountrycode = this.convertCountryCodeToId(addressData.short_name);
					/* …once for the form */
					this.fieldsValues.descriptioncountrycode = this.convertCountryCodeToDescription(addressData.short_name);
				}
			})
		},

		searchPlaceDetails() {
			const placeFromPrediction = this.findPlaceData();
			const request = {
				placeId: placeFromPrediction?.place_id,
				fields: ['address_component']
			}
			this.placeService.getDetails(request, (place, status) => {
				if (status === this.googleMaps.places.PlacesServiceStatus.OK) {
					if (place.address_components) {
						try{
							this.placeId = placeFromPrediction?.place_id;
							this.foundPlaceData = place.address_components;
						}catch(e){
							console.error(`Ho no! An error ${e}`);
						}
						this.populateDataFields();
					}
				}
			})
		},

		searchPlace() {
			let request = { 
				input: this.searchTerm,
				// The restriuction is limited to FIVE countries.
				// https://issuetracker.google.com/issues/74602145?pli=1
				componentRestrictions: {country: ['be', 'nl', 'lu', 'fr', 'de']},
			};
			if (this.searchTerm && this.searchTerm.trim() !== ''){
				this.log("searchPlace function is reached", 'function');
				this.autocompleteService.getPlacePredictions(request, (results, status) => {
					this.predictions = results;
					if (status === this.googleMaps.places.PlacesServiceStatus.OK) {
						this.selectList = this.predictions.map(({description}) => description);
					}
				});
			}
		},

	},

	watch: {
		selectedOption() {
			this.log("watch: selectedOption is watched as it changes…", 'low');
			if (this.selectedOption) {
				this.searchPlaceDetails();
			}
		},

	},

	mounted(){
		this.log("Page mounted, loading Google API", 'low');
		const googleMapApiLoader = new Loader({
			apiKey: this.apiKey,
			version: 'weekly',
			libraries: ["places"]
		});
		this.log("Google loader… loaded!", 'low');
		// this.log(googleMapApiLoader);
		googleMapApiLoader
		.load()
		.then((google) => {
			this.googleMaps = google.maps;
			const map = new google.maps.Map(this.$refs.map); // ! PASS undefined but it should not…
			/* initialize google services */
			this.autocompleteService = new google.maps.places.AutocompleteService();
			this.placeService = new google.maps.places.PlacesService(map);
			this.log("Google API init done!", 'success');
			// this.log(this.autocompleteService);
			// this.log(this.placeService);
		})
		.catch(e => console.log(e));
	},
}